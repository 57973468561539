<template>
<main class="main">
  <header>    
      <h1>Bixby Voice Platform</h1>
      <p class="text-mid">Samsung</p>
    <div class="banner" alt="project thumbnail">
      <img src="@/assets/images/projects/bixby/banner.png" alt="Bixby branding image"/>
    </div>
  </header>
  <div class="section-3 project">
    <section class="section_page" id="bixby_intro">
      <p><i>As in the beginning of any new tech era, voice computing presents some of today’s most exciting UX challenges & opportunities. At Samsung, I got the opportunity to not only work on voice interaction design, but I was also a part of the team designing a voice interface platform from scratch.</i></p>
      <p>Bixby is <strong>Samsung's voice assistant</strong> that can answer questions, perform common commands and automate frequently undertaken tasks. It is in-built into (certain) Samsung phones and appliances.</p>
      <p>Bixby has undergone a lot of changes over the years. I worked on two versions:</p>     
      <ul>
        <li>Bixby Voice: <strong>Voice modality</strong> for almost every task that is possible to perform using the conventional touch interface.</li>
        <li>Bixby 2.0: Smart personalized <strong>voice assistant platform</strong>.</li>
      </ul>     
    </section>
    <section class="section_page" id="bixby_role">
      <h2>My Role & Impact</h2>
      <img src="@/assets/images/projects/bixby/role.png" alt="Worked for all design aspects with focus on Bixby capsule desgn. Owned and shipped 15+ capsules across domains,devices and countries with >90% success rate in understanding intentions and performing desired action and >70% success with real users." />        
    </section>
    <section class="section_page" id="bixby_process">
      <h2>Process (Designing a Bixby capsule)</h2>
      <section class="section_sub">
        <p>As mentioned earlier, I have worked on 15+ capsules (Bixby apps). As the process followed were mostly same (apart from a few alterations), below is a walkthrough of my design process for a Bixby capsule design.</p>
      </section>
      <section class="section_sub">
        <h3 class="header-article-1">Background</h3>
        <p>A capsule refers to a particular capability (service or app) of Bixby. For example: reserving a set at your favourite restuarant.</p> 
        <p>A new capsule unlock more things you can do with Bixby.</p>
        <p>A capsule consists of a series of <strong>moments</strong> which make up a Bixby interaction. The <strong>Bixby Conversation Model has 3 major moments - input, confirmation and result</strong>.</p>
        <img src="@/assets/images/projects/bixby/moment.png" alt="visual representation of Bixby Conversation Model consisting of input, confirmation and result moments" />
        <p>A moment is composed of 3 parts, each individually optimized to move the conversation forward.</p>
        <img src="@/assets/images/projects/bixby/parts.png" alt="Bixby Moment divided into zones. From top to bottom: Bixby dialogue or conversation zone, Bixby views or content zone, Bixby follow-ups or action zone" />
      </section>
      <section class="section_sub">
        <h3 class="header-article-1">Project Goal & Working Process</h3>
        <div class="callout">
          <p>For Samsung users, Bixby is the personalized digital assistant that speaks and learns their language to create a predictive, personalized experience. </p>
          <p>In a capsule, Bixby should coordinate with the content/service provider to leverage Bixby's intelligence to ancipate user behaviors and needs to accelerate tasks.</p>
        </div>
        <div class="cols-dash">
          <div class="col-dash">
            <h4 class="header-article-2">Team & Stakeholders</h4>
            <ul>
              <li>1 voice UX designer, 1 PM, 1-2 developers</li>
              <li>1 QA, 1 UX writer</li>
              <li>Service provider stakeholders</li>
            </ul>
          </div>
          <div class="col-dash">
            <h4 class="header-article-2">Timeline </h4>
            <p>2 weeks / capsule / device</p>
            <p><i>(Excluding testing and bug fixes)</i></p>
          </div>
        </div>
      </section>
      <section class="section_sub" id="bixby_users">
        <h3 class="header-article-1">Target user groups</h3>
      <ul class="list-box">
        <div class="list-layout-col">
          <li class="box-li">
            <img src="@/assets/images/projects/bixby/icons/users.svg" role="presentation"/>
            <h3>Samsung/Bixby users</h3>
            <p>These are the primary users who Bixby as a voice assistant to complete tasks across all Samsung devices. Clear demonstration of Bixby and capsule capabilities will help them customize their personal Bixby experience.</p>
          </li>
        </div>
        <div class="list-layout-col">
          <li class="box-li">
            <img src="@/assets/images/projects/bixby/icons/developers.svg" role="presentation"/>
            <h3>Bixby Capsule Developers</h3>
            <p>They would use shipped capsules as references to create Bixby-validated flows for similar services. So these capsules should be scalable to all the major domain use cases, not just of the particular service.</p>
          </li>
        </div>                      
      </ul>
      </section>     
      <section class="section_sub">
        <h3 class="header-article-1">Design Approach & Deliverables</h3>
        <img class="infographic" src="@/assets/images/projects/bixby/process.png" alt="Infographic with steps in capsule design process: 0. Domain & service research. 1.Defining capsule goals & needs with cross-team to make utterances tightly scoped to a capsule and avoid ambiguity with other overlapping utterances; outcome: scenarios and user stories. 2.Happy path: Delivering result & confirmation screens and dialogues for 'happy flow' or flow in which user reaches the ‘ideal outcome’ in least number of steps. 3.Variables, Use Cases & User Flow Diagrams: Detailed consideration of all paths and edge cases; deliverable: first draft of VUI flows, variables or parameters, conversational driver. 4.Screens and dialogues: Final VUI, dialogues and screens. 5. Testing(before and after release) based on internal testing and user data"/>
        <p>Designing a platform is an inherently evolutionary and open ended process. There is no fixed design scope. The design progressed by evolving and adding experiences.</p>       
      </section>     
      <section class="section_sub">
         <h4 class="header-article-2">More detailed articles on my process and methods</h4>
        <ul>
          <li><a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/design-principles-to-revisit-for-voice-ux-design-5a8b6fd0e8ed">Design principles to revisit for Voice UX</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/maybe-your-voice-assistant-neednt-do-it-all-41971455f">Maybe your voice assistant need not do it all</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/no-my-voice-assistant-neednt-read-me-my-social-media-feed-69052144b6f0">UX Requirements for Voice Interfaces</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/ux-design-for-voice-interfaces-part-ii-3b0056020cd3">Creating Voice Interaction Flows</a></li>
        </ul>      
      </section>      
    </section>    
    <section class="section_page" id="bixby_other">
      <h2>Other notable projects</h2>
      <ul>
        <li><p>Based on my experience on designing capsules, I created the initial content for the <a target="_blank" rel="noopener noreferrer" href="https://bixbydevelopers.com/dev/docs/dev-guide/design-guides/service">Bixby Design Guide</a>.</p></li>
        <li><p>Once Bixby was launched with the Samsung Galaxy Note9, the focus shifted to designing the Bixby experience for Galaxy Home - Samsung's smart speaker. Designing for a voice-only interactions is very different from designing for a multimodal medium. I delved into different conversation theories for <strong>creating the guidelines for Bixby hands-free mode</strong> and revisited Bixby flows for my capsules for voice-only interactions.</p></li>
        <li><p>I was a part of the <strong>team of two to create the Voice UX for Washing Machine, Oven and Vacuum cleaner</strong>. The demo of Samsung Family Hub was presented in CES</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/ksoDjOor5Dg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><p>I also worked on tweaking <strong>Bixby capsules for TV and watch</strong> based on created Bixby guidelines.</p></li>
        <li><p>I <strong>conducted research to further optimize the hands-free guidelines for Samsung earphones</strong>.</p></li>
      </ul>
    </section>
    <section class="section_page" id="bixby_learnings">
      <h2>Learnings</h2>
      <ul>
        <li>
            <h3>Platform/ System Design</h3>
            <p>While designing a scalable platform, I learnt to continuously switch between looking at the system from a broad perspective and zooming into little details.</p>            
        </li>
        <li>
            <h3>Anticipatory Design</h3>
            <p>With a strong intelligence to boast of, Bixby naturally strives to anticipate user's needs through information design, prompts, and suggestions (Conversation drivers). Design-wise, it meant identifying and prioritizing possible suggestions at applicable moments.</p>            
        </li>
        <li>
            <h3>Conversational Dialogue Writing</h3>
            <p>Designing multiple-turn conversations required me to understand the basic rules of conversation (turn-taking, threading, contextual clues, repetition, etc.).</p>            
        </li>
        <li>
            <h3>Design System at scale</h3>
            <p>Working on an ecosystem meant thinking beyond only designing for mobile or screens. It helped to shift focus from devices and UI to ecosystems and design systems.</p>            
        </li>
        <li>
            <h3>Mapping complex decision trees</h3>
            <p>Bixby's core principle to to avoid over-information. To comply with that requires working with complex decision flows to optimize response based on user's utterance, context, previous behavior and external factors.</p>            
        </li>
        <li>
            <h3>Writing</h3>
            <p>Creating the content for the Bixby Design Guide flexed my long-unused writing muscles.</p>            
        </li>
      </ul>
    </section>
  </div>
</main>
  
</template>

<style scoped>
.cols-dash{
  display: grid;
  gap: var(--padding-lg);
  padding-bottom: var(--padding-sm);
}
.infographic{
  margin-top: var(--padding-sm);
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
@media only screen and (min-width: 48rem) { 
  .cols-dash{
    display: grid;
    grid-template-columns: minmax(20rem,auto) max-content;
    
  }
  .col-dash + .col-dash{
    padding-left: var(--padding-sm);
    border-left: 1px dashed var(--primary-color);
  }
}
</style>